@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Services {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 50px 0;

  .background-services {
    background-position: 50% 45%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50vh;
    width: 100%;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 1;
    }

    .container-title {
      padding-top: 50px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 80%;

      .title {
        align-items: center;
        display: flex;
        justify-content: center;

        h1 {
          color: white;
          font-family: $oswald_font;
          font-size: 25px;
          font-weight: bold;
          line-height: 1.5;
          margin: 0;
          padding: 0;
          position: absolute;
          text-align: center;
          z-index: 10;

          @include breakpoint-for(desktop-small) {
            font-size: 35px;
          }
        }

        span {
          color: #ffffff38;
          font-family: $oswald_font;
          font-size: 60px;
          font-weight: bold;
          letter-spacing: 1.5px;
          line-height: 1.5;
          margin: 0;
          padding: 0;
          position: relative;
          text-align: center;
          text-transform: uppercase;
          z-index: 10;

          @include breakpoint-for(desktop-small) {
            font-size: 80px;
          }
        }
      }

      p {
        color: white;
        font-family: $heebo_font;
        font-size: 20px;
        line-height: 1.5;
        margin: 0;
        text-align: center;
        z-index: 10;
      }
    }
  }

  .container-markets {
    max-width: 1170px;
    margin-top: 40px;
    width: 100%;
    display: flex;
    padding: 10px;
    box-sizing: border-box;
    flex-wrap: wrap;

    .container-line {
      display: flex;
      flex-direction: column;
      gap: 40px;
      justify-content: flex-start;
      margin-top: 40px;
      width: 100%;

      @include breakpoint-for(tablet) {
        flex-direction: row;
        font-size: 32px;
        gap: 0;
      }
    }

    .market {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 0px 20px 0px 0px;

      @include breakpoint-for(tablet) {
        align-items: flex-start;
        width: 33%;
        max-width: 380px;
      }

      .header {
        padding: 0px 0px 0px 0px;
        margin-bottom: 10px;
        width: 45px;

        img {
          height: auto;
          max-width: 100%;
          border: none;
          border-radius: 0;
          box-shadow: none;
        }
      }

      .market-body {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include breakpoint-for(tablet) {
          align-items: flex-start;
        }

        h3 {
          font-family: $oswald_font;
          font-size: 24px;
          font-weight: 500;
          line-height: 1.3em;
          margin: 0px 0px 10px 10px;
          padding: 0px;
        }

        p {
          font-family: $heebo_font;
          margin: 0px 0px 0px 10px;
          font-size: 16px;
          font-weight: 300;
          color: #7a7a7a;
          text-align: left;
        }
      }
    }

    .button-market {
      margin: 70px 0 0 0;
      width: 100%;

      a {
        background-color: #0dd7b4;
        border-radius: 5px 5px 5px 5px;
        border-style: solid;
        box-shadow: none;
        color: #ffffff;
        font-family: $oswald_font;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1.3px;
        line-height: 1em;
        border: none;
        padding: 20px 40px 20px 40px;
        text-decoration: none;
        text-transform: uppercase;
        transition: all 0.4s ease;

        &:hover {
          color: black;
        }
      }
    }
  }
}
