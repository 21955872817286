@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Partners {
  .background-partners {
    background-position: 50% 80%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50vh;
    width: 100%;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 1;
    }

    .container-title {
      padding-top: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80%;

      h1 {
        color: white;
        font-family: $oswald_font;
        font-size: 25px;
        font-weight: bold;
        line-height: 1.5;
        margin: 0;
        padding: 0;
        position: absolute;
        text-align: center;
        z-index: 10;

        @include breakpoint-for(desktop-small) {
          font-size: 35px;
        }
      }

      span {
        color: #ffffff38;
        font-family: $oswald_font;
        font-size: 60px;
        font-weight: bold;
        letter-spacing: 1.5px;
        line-height: 1.5;
        margin: 0;
        padding: 0;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        z-index: 10;

        @include breakpoint-for(desktop-small) {
          font-size: 80px;
        }
      }
    }
  }

  .container-partners {
    display: flex;
    flex-direction: column;
    padding-bottom: 100px;
    align-items: center;

    .container-partners-title {
      padding: 50px 15px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;

      .title {
        display: flex;
        align-items: center;
        justify-content: center;

        h1 {
          color: black;
          font-family: $oswald_font;
          font-size: 25px;
          font-weight: bold;
          line-height: 1.5;
          margin: 0;
          padding: 0;
          position: absolute;
          text-align: center;
          z-index: 10;

          @include breakpoint-for(desktop-small) {
            font-size: 35px;
          }
        }

        span {
          color: #04020230; // COR PADRÃO DO TITULO EM BRANCO
          font-family: $oswald_font;
          font-size: 60px;
          font-weight: bold;
          letter-spacing: 1.5px;
          line-height: 1.5;
          margin: 0;
          padding: 0;
          position: relative;
          text-align: center;
          text-transform: uppercase;
          z-index: 10;

          @include breakpoint-for(desktop-small) {
            font-size: 80px;
          }
        }
      }

      p {
        color: #04020250; // COR SECUNDÁRIO DO TITULO EM BRANCO
        font-family: $heebo_font;
        font-size: 15px;
        line-height: 1.5;
        margin: 0;
        text-align: center;
        z-index: 10;

        @include breakpoint-for(desktop-small) {
          font-size: 20px;
        }
      }
    }

    .profissionals-partners {
      display: flex;
      justify-content: center;
      gap: 30px;
      align-items: center;
      margin-top: 100px;
      padding-bottom: 20px;
      max-width: 1200px;
      flex-wrap: wrap;

      .profissional {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.127);
        box-sizing: border-box;
        height: 400px;
        padding: 20px;
        min-width: 250px;
        max-width: 250px;

        .profissional-image {
          border-radius: 50%;
          width: 150px;
        }
      }

      .infos-title {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 50px;

        span {
          color: black;
          font-family: $oswald_font;
          font-size: 23px;
          font-weight: bold;
          line-height: 1.5;
          margin: 0;
          padding: 0;
          text-align: center;
          z-index: 10;
        }

        p {
          color: #04020250; // COR SECUNDÁRIO DO TITULO EM BRANCO
          font-family: $heebo_font;
          font-size: 13px;
          line-height: 1.5;
          margin: 0;
          text-align: center;
          z-index: 10;
        }
      }

      .buttons-partners {
        display: flex;
        margin-top: 30px;
        justify-content: center;
        gap: 20px;

        a {
          align-items: center;
          background-color: #04020250;
          border-radius: 50%;
          border: unset;
          cursor: pointer;
          display: flex;
          height: 30px;
          justify-content: center;
          padding: 0;
          width: 30px;
          margin: 10px 0;

          .mdi--instagram {
            display: inline-block;
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4zm9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3'/%3E%3C/svg%3E");
          }

          .ri--facebook-fill {
            display: inline-block;
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4z'/%3E%3C/svg%3E");
          }

          .pajamas--mail {
            display: inline-block;
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='white' fill-rule='evenodd' d='M2 3.5h12a.5.5 0 0 1 .5.5v.572L8 8.286L1.5 4.572V4a.5.5 0 0 1 .5-.5m-.5 2.8V12a.5.5 0 0 0 .5.5h12a.5.5 0 0 0 .5-.5V6.3L8.372 9.8L8 10.014L7.628 9.8zM0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2z' clip-rule='evenodd'/%3E%3C/svg%3E");
          }
        }
      }
    }

    .gallery-partners {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
      max-width: 1400px;
      margin-top: 20px;

      .image-item {
        width: 280px;
        height: 150px;

        .thumbnail {
          width: 100%;
          height: 100%;
        }
      }
    }

    .text-partners {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 1190px;
      margin-top: 80px;
      padding: 10px;
      box-sizing: border-box;

      p {
        color: #04020250;
        font-family: $heebo_font;
        font-size: 20px;
        line-height: 1.5;
        margin: 0;
        text-align: center;
        z-index: 10;
      }
    }
  }
}
