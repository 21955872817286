@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.ArtistsHome {
  min-height: 100vh;
  padding: 50px;
  box-sizing: border-box;
  background-color: black;

  .container-title {
    padding-top: 50px;

    .widget {
      align-items: center;
      display: flex;
      justify-content: center;

      h1 {
        color: white;
        font-family: $oswald_font;
        font-size: 26px;
        font-weight: bold;
        line-height: 1.5;
        margin: 0;
        padding: 0;
        position: absolute;
        text-align: center;
        z-index: 10;
      }

      span {
        color: #ffffff38;
        font-family: $oswald_font;
        font-size: 80px;
        font-weight: bold;
        letter-spacing: 1.5px;
        line-height: 1.5;
        margin: 0;
        padding: 0;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        z-index: 10;
      }
    }
  }

  .container-artists {
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @include breakpoint-for(desktop-medium) {
      padding: 50px;
    }

    .box-artists {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
      max-width: 1200px;

      .Artist {
        height: 400px !important;
        width: 300px !important;

        @include breakpoint-for(desktop-medium) {
          height: 505px !important;
          width: 360px !important;
        }
      }
    }

    .btn-artists {
      background-color: #0dd7b4;
      border-radius: 5px;
      border-style: solid;
      border-width: 0;
      color: #FFFFFF;
      cursor: pointer;
      font-family: $oswald_font;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.5px;
      line-height: 1.5;
      margin-top: 50px;
      padding: 20px 40px;
      text-align: center;
      text-transform: uppercase;
    }
  }
}
