.image-item {
  .thumbnail {
      width: 100px;
      height: 100px;
      object-fit: cover;
      cursor: pointer;
      border: 2px solid #ddd;
      border-radius: 5px;
      transition: transform 0.3s ease;

      &:hover {
          transform: scale(1.1);
      }
  }

  .modal {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 1000;

      .close {
          position: absolute;
          top: 20px;
          right: 20px;
          color: white;
          font-size: 30px;
          cursor: pointer;
          z-index: 1001;
      }

      .modal-content {
          max-width: 90%;
          max-height: 90%;
          border-radius: 5px;
      }
  }
}
