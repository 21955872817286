@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 50px 0;

  .background-contact { 
    background-position: 50% 30%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50vh;
    width: 100%;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 1;
    }

    .container-title {
      padding-top: 50px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 80%;

      .title {
        align-items: center;
        display: flex;
        justify-content: center;

        h1 {
          color: white;
          font-family: $oswald_font;
          font-size: 25px;
          font-weight: bold;
          line-height: 1.5;
          margin: 0;
          padding: 0;
          position: absolute;
          text-align: center;
          z-index: 10;

          @include breakpoint-for(desktop-small) {
            font-size: 35px;
          }
        }

        span {
          color: #ffffff38;
          font-family: $oswald_font;
          font-size: 60px;
          font-weight: bold;
          letter-spacing: 1.5px;
          line-height: 1.5;
          margin: 0;
          padding: 0;
          position: relative;
          text-align: center;
          text-transform: uppercase;
          z-index: 10;

          @include breakpoint-for(desktop-small) {
            font-size: 80px;
          }
        }
      }

      p {
        color: white;
        font-family: $heebo_font;
        font-size: 20px;
        line-height: 1.5;
        margin: 0;
        text-align: center;
        z-index: 10;
      }
    }
  }

  .container-contact {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    flex-direction: column;

    @include breakpoint-for(desktop-small) {
      flex-direction: row;
    }

    .box-contact {
      max-width: 1200px;
      width: 100%;
     
      .info-maps {
        width: 100%;

        @include breakpoint-for(desktop-small) {
          width: 50%;
        }

        .box-map {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 20px; /* Ajuste conforme necessário */
          box-sizing: border-box;

          h1 {
            font-size: 20px;
            font-weight: bold;
            font-family: $oswald_font;
            margin: 0 0 10px 0;
            width: 100%;
            padding: 0;
            text-align: left;

            @include breakpoint-for(desktop-small) {
              font-size: 40px;  
            }
          }
        
          .responsive-iframe {
            width: 100%;
            height: 450px;
            border: 0;
          }

          .info-map {
            align-items: center;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            width: 100%;

            p {
              font-size: 16px;
              font-weight: bold;
              font-family: $oswald_font;
              margin: 0 0 10px 0;
              width: 100%;
              padding: 0;
              text-align: left;

              @include breakpoint-for(desktop-small) {
                font-size: 20px;  
              }
            } 
          }
        }

        .container-infos-click {
          padding: 20px;
          width: 100%;
          display: flex;
          box-sizing: border-box;
          flex-direction: column-reverse;

          @include breakpoint-for(desktop-small) {
            flex-direction: row;
          }

          ul {
            width: 100%;
            padding: 0;
            margin: 0;

            @include breakpoint-for(desktop-small) {
              width: 50%;
              margin: 15px 0;
            }

            li {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 10px;
              margin: 10px 0;
              font-family: $heebo_font;
              font-size: 15px;

              .fa--phone {
                display: inline-block;
                width: 15px;
                height: 15px;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1408 1408'%3E%3Cpath fill='%230ad8a5' d='M1408 1112q0 27-10 70.5t-21 68.5q-21 50-122 106q-94 51-186 51q-27 0-53-3.5t-57.5-12.5t-47-14.5T856 1357t-49-18q-98-35-175-83q-127-79-264-216T152 776q-48-77-83-175q-3-9-18-49t-20.5-55.5t-14.5-47T3.5 392T0 339q0-92 51-186Q107 52 157 31q25-11 68.5-21T296 0q14 0 21 3q18 6 53 76q11 19 30 54t35 63.5t31 53.5q3 4 17.5 25t21.5 35.5t7 28.5q0 20-28.5 50t-62 55t-62 53t-28.5 46q0 9 5 22.5t8.5 20.5t14 24t11.5 19q76 137 174 235t235 174q2 1 19 11.5t24 14t20.5 8.5t22.5 5q18 0 46-28.5t53-62t55-62t50-28.5q14 0 28.5 7t35.5 21.5t25 17.5q25 15 53.5 31t63.5 35t54 30q70 35 76 53q3 7 3 21'/%3E%3C/svg%3E");
              }

              .fa--envelope-o {
                display: inline-block;
                width: 15px;
                height: 15px;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1792 1408'%3E%3Cpath fill='%230ad8a5' d='M1664 1248V480q-32 36-69 66q-268 206-426 338q-51 43-83 67t-86.5 48.5T897 1024h-2q-48 0-102.5-24.5T706 951t-83-67Q465 752 197 546q-37-30-69-66v768q0 13 9.5 22.5t22.5 9.5h1472q13 0 22.5-9.5t9.5-22.5m0-1051v-24.5l-.5-13l-3-12.5l-5.5-9l-9-7.5l-14-2.5H160q-13 0-22.5 9.5T128 160q0 168 147 284q193 152 401 317q6 5 35 29.5t46 37.5t44.5 31.5T852 887t43 9h2q20 0 43-9t50.5-27.5T1035 828t46-37.5t35-29.5q208-165 401-317q54-43 100.5-115.5T1664 197m128-37v1088q0 66-47 113t-113 47H160q-66 0-113-47T0 1248V160Q0 94 47 47T160 0h1472q66 0 113 47t47 113'/%3E%3C/svg%3E");
              }

              .ph--map-pin-fill {
                display: inline-block;
                width: 15px;
                height: 15px;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256'%3E%3Cpath fill='%230AD8A5' d='M128 16a88.1 88.1 0 0 0-88 88c0 75.3 80 132.17 83.41 134.55a8 8 0 0 0 9.18 0C136 236.17 216 179.3 216 104a88.1 88.1 0 0 0-88-88m0 56a32 32 0 1 1-32 32a32 32 0 0 1 32-32'/%3E%3C/svg%3E");
              }

              .ic--baseline-whatsapp {
                display: inline-block;
                width: 20px;
                height: 20px;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%230AD8A5' d='M19.05 4.91A9.816 9.816 0 0 0 12.04 2c-5.46 0-9.91 4.45-9.91 9.91c0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21c5.46 0 9.91-4.45 9.91-9.91c0-2.65-1.03-5.14-2.9-7.01m-7.01 15.24c-1.48 0-2.93-.4-4.2-1.15l-.3-.18l-3.12.82l.83-3.04l-.2-.31a8.264 8.264 0 0 1-1.26-4.38c0-4.54 3.7-8.24 8.24-8.24c2.2 0 4.27.86 5.82 2.42a8.183 8.183 0 0 1 2.41 5.83c.02 4.54-3.68 8.23-8.22 8.23m4.52-6.16c-.25-.12-1.47-.72-1.69-.81c-.23-.08-.39-.12-.56.12c-.17.25-.64.81-.78.97c-.14.17-.29.19-.54.06c-.25-.12-1.05-.39-1.99-1.23c-.74-.66-1.23-1.47-1.38-1.72c-.14-.25-.02-.38.11-.51c.11-.11.25-.29.37-.43s.17-.25.25-.41c.08-.17.04-.31-.02-.43s-.56-1.34-.76-1.84c-.2-.48-.41-.42-.56-.43h-.48c-.17 0-.43.06-.66.31c-.22.25-.86.85-.86 2.07c0 1.22.89 2.4 1.01 2.56c.12.17 1.75 2.67 4.23 3.74c.59.26 1.05.41 1.41.52c.59.19 1.13.16 1.56.1c.48-.07 1.47-.6 1.67-1.18c.21-.58.21-1.07.14-1.18s-.22-.16-.47-.28'/%3E%3C/svg%3E");
              }

              a {
                color: black;
                font-family: $heebo_font;
                font-size: 15px;
                display: flex;
                text-align: left;
                gap: 10px;
                text-decoration: unset;

                &:hover {
                  color: #0ad8a5;
                }
              }
            }
          }
        }
      }
    }
  }

}