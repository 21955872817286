@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.StudioHome {
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  padding: 0 15px 50px;
  height: 100%;
  position: relative;

  .background-studio-two {
    background-position: 50% 60%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 20vh;
    width: 100%;
    position: relative;
    padding-bottom: 100px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.902);
      z-index: 1;
    }
  }

  .background-studio {
    background-position: 50% 45%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 40vh;
    width: 100%;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 1;
    }

    .container-title {
      padding-top: 50px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      height: 80%;

      .title {
        align-items: center;
        display: flex;
        justify-content: center;

        h1 {
          color: white;
          font-family: $oswald_font;
          font-size: 25px;
          font-weight: bold;
          line-height: 1.5;
          margin: 0;
          padding: 0;
          position: absolute;
          text-align: center;
          z-index: 10;

          @include breakpoint-for(desktop-small) {
            font-size: 35px;
          }
        }

        span {
          color: #ffffff38;
          font-family: $oswald_font;
          font-size: 60px;
          font-weight: bold;
          letter-spacing: 1.5px;
          line-height: 1.5;
          margin: 0;
          padding: 0;
          position: relative;
          text-align: center;
          text-transform: uppercase;
          z-index: 10;

          @include breakpoint-for(desktop-small) {
            font-size: 80px;
          }
        }
      }

      p {
        color: white;
        font-family: $heebo_font;
        font-size: 20px;
        line-height: 1.5;
        margin: 0;
        text-align: center;
        z-index: 10;
      }
    }
  }

  .container-cards {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    margin-top: -100px;

    a {
      margin-top: 50px;
      align-items: center;
      background-color: #0dd7b4;
      border-radius: 5px 5px 5px 5px;
      border: none;
      color: #ffffff;
      cursor: pointer;
      display: flex;
      font-family: $oswald_font;
      font-weight: 500;
      gap: 5px;
      letter-spacing: 1.3px;
      padding: 10px 30px 10px 30px;
      text-decoration: unset;
      text-transform: uppercase;

      a {
        text-decoration: none;
        color: white;
      }
    }

    .content {
      background-color: white;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
      color: black;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;
      padding: 10px;
      box-sizing: border-box;
      text-align: center;
      top: 0%;
      z-index: 1;
      width: 100%;

      @include breakpoint-for(desktop-small) {
        justify-content: space-between;
        padding: 50px;
        width: 95%;
      }

      .card-studio {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;

        @include breakpoint-for(desktop-small) {
          max-width: 250px;
        }

        @include breakpoint-for(desktop-medium) {
          max-width: 300px;
        }

        .background-card {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          height: 300px;
          width: 100%;
          border-radius: 5px;

          @include breakpoint-for(desktop-small) {
            height: 200px;
            width: 100%;
          }

          @include breakpoint-for(desktop-medium) {
            height: 200px;
            width: 100%;
          }
        }

        p {
          color: black;
          font-family: "Oswald", sans-serif;
          font-size: 20px;
          text-align: left;
          width: 100%;
        }

        button {
          align-items: center;
          background-color: #0dd7b4;
          border-radius: 5px 5px 5px 5px;
          border: none;
          margin: 0;
          color: #ffffff;
          cursor: pointer;
          display: flex;
          font-family: $oswald_font;
          font-weight: 500;
          gap: 5px;
          letter-spacing: 1.3px;
          padding: 10px 30px 10px 30px;
          text-decoration: unset;
          text-transform: uppercase;
        }
      }
    }
  }
}
