.portfolio-gallery {
  .row-items {
    display: flex;
    justify-content: space-between;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      transition: opacity 0.4s ease; 
      z-index: -1;
      opacity: 0;
    }

    &:hover::before {
      opacity: 1; 
    }

    .row-item {
      flex: 0 0 calc(100% / 5);
      max-width: calc(100% / 5);
      border-right: 1px solid rgba(255, 255, 255, 0.3);
      height: 90vh;
      position: relative;
      transition: all 0.4s ease; 

      &:hover {
        transform: translateY(100px);
        background: rgba(0, 0, 0, .4);
        z-index: 1; 
      }

      .row-item-info {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 150px;
        background: #fff; 
        transform: translateY(-100%); 
        transition: all 0.4s ease; 

      }
    }
  }

  .gallery-items {
    display: flex;
    justify-content: space-between;

    .image-item {
      display: none;

      &.current-item {
        display: block !important;
      }
    }
  }
}
