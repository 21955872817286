@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.header-home {
  position: relative;
  overflow: hidden;

  .background-slideshow {
    width: 100%;
    height: 80vh;

    .swiper-wrapper {
      height: 100%;
    }

    .background-slideshow__slide {
      background-position: center;
      background-size: cover;
      height: 100%;
      width: 100%;

      .background-slideshow__slide__image {
        animation: scaleAnimation 10s linear infinite;
        background-position: center;
        background-size: cover;
        height: 100%;
        width: 100%;
      }
    }
  }

  .background-overlay {
    background-color: #121212;
    height: 100%;
    left: 0;
    opacity: 0.7;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
  }

  .container {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 15;
  }

  .widget {
    align-items: center;
    display: flex;
    justify-content: center;

    h1 {
      color: white;
      font-family: $oswald_font;
      font-size: 30px;
      font-weight: bold;
      line-height: 1.5;
      margin: 0;
      padding: 0;
      position: absolute;
      text-align: center;
      top: 20px;
      z-index: 40;

      @include breakpoint-for(desktop-small) {
        top: 25px;
        font-size: 56px;
      }
    }

    span {
      color: #ffffff38;
      font-family: $oswald_font;
      font-size: 60px;
      font-weight: bold;
      letter-spacing: 1.5px;
      line-height: 1.5;
      margin: 0;
      padding: 0;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      z-index: 40;

      @include breakpoint-for(desktop-small) {
        font-size: 164px;
      }
    }

    &.absolute {
      position: absolute;
    }
  }

  .heading-title {
    color: red;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    z-index: 40;

    span {
      color: white;
    }
  }

  @media screen and (max-width: 768px) {
    .heading-title {
      font-size: 1.5rem;
    }
  }
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1.1);
  }
}
