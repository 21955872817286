@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Studios {
  .background-studios {
    background-position: 50% 60%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50vh;
    width: 100%;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 1;
    }

    .container-title {
      padding-top: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80%;

      h1 {
        color: white;
        font-family: $oswald_font;
        font-size: 25px;
        font-weight: bold;
        line-height: 1.5;
        margin: 0;
        padding: 0;
        position: absolute;
        text-align: center;
        z-index: 10;

        @include breakpoint-for(desktop-small) {
          font-size: 35px;
        }
      }

      span {
        color: #ffffff38;
        font-family: $oswald_font;
        font-size: 60px;
        font-weight: bold;
        letter-spacing: 1.5px;
        line-height: 1.5;
        margin: 0;
        padding: 0;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        z-index: 10;

        @include breakpoint-for(desktop-small) {
          font-size: 80px;
        }
      }
    }
  }

  .all-studios {
    display: flex;
    justify-content: center;

    .container-studios {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 50px 15px;
      max-width: 1140px;
      box-sizing: border-box;
      flex-direction: column;

      @include breakpoint-for(desktop-small) {
        flex-direction: row;
      }

      .studios-infos {
        width: 100%;

        @include breakpoint-for(desktop-small) {
          width: 60%;

          .studio-info {
            padding: 50px;
          }
        }

        .studio-info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          h1 {
            color: black;
            font-family: $oswald_font;
            font-size: 25px;
            font-weight: bold;
            line-height: 1.5;
            margin: 0 0 20px 0;
            padding: 0;
            text-align: center;
            z-index: 10;

            @include breakpoint-for(desktop-small) {
              font-size: 35px;
            }
          }

          .details-summary {
            .card-body {
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              padding: 30px;
              width: 100%;

              @include breakpoint-for(desktop-small) {
                padding: 30px 85px 30px 65px;
              }

              p {
                text-align: left;
                margin-top: 0;
                font-family: $heebo_font;
                color: #7a7a7a;
                margin-bottom: 0.9rem;
              }
            }
          }
        }
      }

      .right-infos {
        padding: 50px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        .container-info {
          background-color: #49494c;
          padding: 50px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          border-radius: 10px;

          span {
            color: white;
            font-family: $oswald_font;
            font-size: 20px;
            font-weight: bold;
            line-height: 1.5;
            margin: 0;
            padding: 0;
            text-align: center;
            z-index: 10;

            @include breakpoint-for(desktop-small) {
              font-size: 25px;
            }
          }

          p {
            color: white;
            font-family: $heebo_font;
            font-size: 15px;
            line-height: 1.5;
            margin: 0;
            padding: 0;
            text-align: center;
            z-index: 10;

            @include breakpoint-for(desktop-small) {
              font-size: 15px;
            }
          }

          ul {
            margin: 15px 0;
            padding: 0;

            li {
              display: flex;
              align-items: center;
              gap: 10px;
              margin: 10px 0;

              .fa--phone {
                display: inline-block;
                width: 15px;
                height: 15px;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1408 1408'%3E%3Cpath fill='%230ad8a5' d='M1408 1112q0 27-10 70.5t-21 68.5q-21 50-122 106q-94 51-186 51q-27 0-53-3.5t-57.5-12.5t-47-14.5T856 1357t-49-18q-98-35-175-83q-127-79-264-216T152 776q-48-77-83-175q-3-9-18-49t-20.5-55.5t-14.5-47T3.5 392T0 339q0-92 51-186Q107 52 157 31q25-11 68.5-21T296 0q14 0 21 3q18 6 53 76q11 19 30 54t35 63.5t31 53.5q3 4 17.5 25t21.5 35.5t7 28.5q0 20-28.5 50t-62 55t-62 53t-28.5 46q0 9 5 22.5t8.5 20.5t14 24t11.5 19q76 137 174 235t235 174q2 1 19 11.5t24 14t20.5 8.5t22.5 5q18 0 46-28.5t53-62t55-62t50-28.5q14 0 28.5 7t35.5 21.5t25 17.5q25 15 53.5 31t63.5 35t54 30q70 35 76 53q3 7 3 21'/%3E%3C/svg%3E");
              }

              .fa--envelope-o {
                display: inline-block;
                width: 15px;
                height: 15px;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1792 1408'%3E%3Cpath fill='%230ad8a5' d='M1664 1248V480q-32 36-69 66q-268 206-426 338q-51 43-83 67t-86.5 48.5T897 1024h-2q-48 0-102.5-24.5T706 951t-83-67Q465 752 197 546q-37-30-69-66v768q0 13 9.5 22.5t22.5 9.5h1472q13 0 22.5-9.5t9.5-22.5m0-1051v-24.5l-.5-13l-3-12.5l-5.5-9l-9-7.5l-14-2.5H160q-13 0-22.5 9.5T128 160q0 168 147 284q193 152 401 317q6 5 35 29.5t46 37.5t44.5 31.5T852 887t43 9h2q20 0 43-9t50.5-27.5T1035 828t46-37.5t35-29.5q208-165 401-317q54-43 100.5-115.5T1664 197m128-37v1088q0 66-47 113t-113 47H160q-66 0-113-47T0 1248V160Q0 94 47 47T160 0h1472q66 0 113 47t47 113'/%3E%3C/svg%3E");
              }

              a {
                color: white;
                font-family: $heebo_font;
                font-size: 15px;
                text-decoration: unset;

                &:hover {
                  color: #0ad8a5;
                }
              }
            }
          }
        }

        .button-info {
          color: #ffffff;
          background-color: #00ffe1;
          border-style: solid;
          border-width: 0;
          border-radius: 5px;
          padding: 20px 40px;
          text-decoration: unset;
          font-family: $heebo_font;
          font-size: 15px;
          font-weight: bold;
          line-height: 1.5;
          text-align: center;
          cursor: pointer;

          @include breakpoint-for(desktop-small) {
            font-size: 20px;
          }
        }
      }
    }
  }
}
