@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Navbar {
  display: flex;
  justify-content: center;
  height: 82px;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 5px 0;
  z-index: 100;

  .navbar-logo {
    img {
      height: auto;
      max-width: 100%;
      border: none;
      border-radius: 0;
      box-shadow: none;
      height: 100%;
    }
  }

  .navbar-container {
    display: flex;
    max-width: 1140px;
    width: 100%;
    padding: 10px;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    position: relative;
  }

  .navbar-menu {
    display: none;

    .navbar-list {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding-left: 0;
      margin: 0;
      list-style: none;

      .navbar-item {
        margin: 0;
        padding: 0;
        list-style: none;

        a {
          font-family: $heebo_font;
          padding: 0px 18px 0px 18px;
          color: white;
          // letter-spacing: 1.2px;
          font-size: 16px;
          transition: all 0.4s ease;
          text-transform: none;
          font-weight: 500;
          height: 100%;
          align-items: center;
          text-decoration: none;

          &:hover {
            color: #0dd7b4;
          }
        }

        &.active {
          a {
            color: #0dd7b4;
          }
        }
      }
    }

    .close {
      background-color: #0ad8a5;
      border: none;
      position: absolute;
      border-radius: 10px;
      top: 30px;
      right: 20px;
      display: flex;

      @include breakpoint-for(desktop-small) {
        display: none;
      }

      .majesticons--close-line {
        display: inline-block;
        width: 35px;
        height: 35px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 12L7 7m5 5l5 5m-5-5l5-5m-5 5l-5 5'/%3E%3C/svg%3E");
      }
    }

    .navbar-open {
      display: none;
    }

    &.open {
      display: block;
      position: fixed;
      padding: 10px;
      box-sizing: border-box;
      top: 0;
      left: 0;
      height: 100vh;
      background-color: #f7f7f7;
      width: 75%;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      animation: navOpenAnimation 0.4s ease;

      @keyframes navOpenAnimation {
        0% {
          left: -200%;
        }
        100% {
          left: 0%;
        }
      }

      .navbar-open {
        display: flex;

        .navbar-logo {
          img {
            height: auto;
            max-width: 139px;
            border: none;
            border-radius: 0;
            box-shadow: none;
            height: 81px;
          }
        }
      }

      .navbar-list {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 40px 0;

        .navbar-item {
          margin: 10px 0;

          a {
            color: black;
            font-size: 18px;
          }

          &.active {
            a {
              color: #0dd7b4;
            }
          }
        }
      }
    }
  }

  .navbar-mobile {
    display: flex;

    .hamburger {
      background: none;
      border: none;
      font-size: 24px;
      cursor: pointer;
      color: #0ad8a5;
    }
  }

  @include breakpoint-for(desktop-small) {
    .navbar-menu {
      display: flex;
    }

    .navbar-mobile {
      display: none;
    }
  }

  .navbar-desktop {
    display: none;
    align-items: center;

    @include breakpoint-for(desktop-small) {
      display: flex;
    }

    .navbar-button {
      display: flex;

      a {
        display: flex;
        align-items: center;
        gap: 5px;
        background-color: #0dd7b4;
        border-radius: 5px;
        color: #ffffff;
        font-family: $oswald_font;
        font-weight: 500;
        letter-spacing: 1.3px;
        padding: 10px 30px;
        text-decoration: unset;
        text-transform: uppercase;
        transition-duration: 0.3s;
        transition-property: transform;
        transition-timing-function: ease-out;

        .ph--phone-fill {
          display: inline-block;
          width: 18px;
          height: 18px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256'%3E%3Cpath fill='white' d='M231.88 175.08A56.26 56.26 0 0 1 176 224C96.6 224 32 159.4 32 80a56.26 56.26 0 0 1 48.92-55.88a16 16 0 0 1 16.62 9.52l21.12 47.15v.12A16 16 0 0 1 117.39 96c-.18.27-.37.52-.57.77L96 121.45c7.49 15.22 23.41 31 38.83 38.51l24.34-20.71a8 8 0 0 1 .75-.56a16 16 0 0 1 15.17-1.4l.13.06l47.11 21.11a16 16 0 0 1 9.55 16.62'/%3E%3C/svg%3E");
        }

        &:active,
        &:focus,
        &:hover {
          transform: translateY(-8px);
        }
      }
    }
  }
}
